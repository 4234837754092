
.previewContainer{
  max-width: 150px;
  height: 100%;
  display: inline-block;
  position: relative;
  justify-content: center;
}
.delete-icon {
  transform: scale(0);
  position: absolute;
  z-index: 2;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.delete-icon:hover {
  transform: scale(1);
}

.faturaImg {
  cursor: pointer;
}

.pdfContainer {
  text-align: center;
  display: inline;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
};

