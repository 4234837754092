body {
    margin: 0;
    font-family: 'Montserrat'!important;
}

#root {
    height: 100%;
}

.app {
    font-family: 'Montserrat'!important;
    font-style: normal;
}

.layout {
    background: #fff;
    /* min-height: 100vh; */
    min-height: calc(100% - 116px);
}

.content {
    margin-top: 100px;
}
.disable {
    cursor: not-allowed;
    pointer-events: none;
}
.isDisable {
    cursor: not-allowed;
    pointer-events: none;
    color: #d8d8d8 !important;
    text-decoration-color: #d8d8d8 !important;
}
.text-center{
    text-align: center !important;
}
.loginBox {
    width: 452px;
    flex-grow: 0;
    padding: 50px 32px;
    border-radius: 15px;
    border: solid 1px #0d0f7b;
    background-color: #fff;
    padding-top: 24px!important;
}

.loginBox img {
    width: 242px;
    height: 47.5px;
    flex-grow: 0;
    margin: 0 0px 11.5px 0;
    object-fit: contain;
    margin-left: 20px
}

.loginBox input {
    width: 288px;
    height: 48px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    background-color: #fff !important;
}

.loginBox button {
    width: 288px!important;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    margin-top: 15px;
}

.loginCard {
    border: none!important;
}

.loginSpan {
    flex-grow: 0;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: left;
    color: #0d0f7b;
}

.login-btn {
    width: 100% !important;
}

.email-input {
    margin: 0px;
}

.email-input input {  
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 0;
    padding: 16px 12px;
    border-radius: 8px;
    font-size: 15px;
}


.ant-input-password {
    width: 100%;
    height: 46px;
    margin-top: 16px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    background-color: #fff !important;
    padding: 0px;
}

.ant-input-password input {
    height: 44px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px ​0px 10px 14px !important;
    text-indent: 10px;
    background-color: #fff !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ant-input-password .ant-input-suffix {
    padding-right: 14px;
}

.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #1251a5;  
    /* height: auto; */
    border-radius: 8px;    
    border-color: #1251a5;  
}

.header-login {
    height: 64.8px;
    padding: 12px 0;
    object-fit: contain;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    justify-content: center;
  }
.loginCard .ant-card-body {
    padding-left: 12px!important;
    padding-top: 36px!important;
}

.logoContainer {
    width: 100%;
    margin-bottom: 45px;
}

.head-bar { 
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.head-bar .header-btn{ 
    background: #fff;
    border: 0px;
    box-shadow: none;
    border-bottom: 2px solid #fff;
    border-radius: 0px;
    padding: 0px 0px;
    font-weight: 900;
    margin-right: 40px;
    box-shadow: 0;
}

.active-line,
.head-bar .header-btn:active,
.head-bar .header-btn:focus,
.head-bar .header-btn:hover {
    border: 0px;
    border-bottom: 2px solid #0d0f7b !important;
    color: #0d0f7b;
}

.tab-box {
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 6px 40px 5px;
    margin: 30px 10px 10px;
    border-radius: 56px;
    border: solid 2px;
    background-color: #fff;
    color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.upload-box {
    width: 466px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin: 0px 0px 20px 0px;
    padding: 32px;
    border-radius: 8px;
    background-color: #fff;
}

.upload-box .ant-divider-with-text {
    line-height: 1px !important;
}

.upload-title {  
    width: 200px;
    height: 21px;
    flex-grow: 0;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    align-self: normal;
    color: #131112;
    margin-left: 0;
}

.upload-btn {
    width: 365px !important;
    height: 48px;  
    border-radius: 8px;
    background-color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.tedarikci-btn .upload-btn {
    width: 220px !important;
}

.tab-send-btn {
    width: 135px !important;
    height: 46.5px;
    border-radius: 0px;
    background-color: #1251a5;
    font-size: 14px;
    font-weight: bolder;
    margin-left: -1px;
    border-color: #1251a5;
    color: #fff;
}

.popup-send-btn {
    background-color: #0d0f7b;
    font-size: 14px;
    height: 46px;
    margin: 20px auto;
    font-weight: bolder;
    border-radius: 8px;
    border-color: #0d0f7b;
    color: #fff;
}

.popup-send-btn:hover, .popup-send-btn:focus, .popup-send-btn:active {
    background-color: #1251a5;
    border-color: #1251a5;
    color: #fff;
}

.header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0d0f7b;
}
.header .ant-tabs-ink-bar {
    background-color: #0d0f7b;
}

.tab-send-btn:hover, .tab-send-btn:focus, .tab-send-btn:active {
    background-color: #0d0f7b;
    border-color: #0d0f7b;
    color: #fff;
}

/* .upload-tabs .ant-tabs-nav {
    width: 895px !important;
} */

.upload-tabs {
    width: 100% !important;
}

.upload-tabs .ant-tabs-tab {
    background-color: #e6f1ff !important;
    color: #000 !important;
}

.upload-tabs .ant-tabs-tab-active {
    background-color: #0d0f7b !important;
    color: #fff !important;
}

.upload-tabs .ant-tabs-tab .ant-tabs-tab-remove{
    font-size: 18px;
    color: #000;
}

.upload-tabs  .ant-tabs-tab-active .ant-tabs-tab-remove{
    font-size: 18px;
    color: #fff;
}


.upload-tabs .ant-tabs-tab .upload-tab-title {
    margin: 0px; 
    color: #000;
}

.upload-tabs .ant-tabs-tab-active .upload-tab-title {
    margin: 0px; 
    color: #fff;
}

.upload-tabs .ant-tabs-nav-add {
    margin-left: -1px !important;
    padding: 0px !important;
}

.upload-tab-btn button {
    width: 165px !important;
    height: 46.5px;
    border-radius: 0px;
    background-color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
    margin-left: -1px;
    border-color: #0d0f7b;
}

.upload-tab-btn button:hover, .upload-tab-btn button:focus, .upload-tab-btn button:active {
    width: 165px !important;
    height: 46.5px;
    border-radius: 0px;
}

.tab-download-btn {
    width: 285px !important;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 17px;
    border-radius: 0px;
    background-color: #f4f4f4;  
    color: #0d0f7b;
    font-size: 14px;
    font-weight: bolder;
}

.download-btn {
    width: 285px !important;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 17px;
    border-radius: 8px;
    background-color: #f4f4f4;  
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

.financeSpan {
    width: 100%;
    text-align: center;
    flex-grow: 0;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #107c41;
}

.welcomeSpan {
    width: 288px;
    height: 25px;
    flex-grow: 0;
    margin: 43px 0 11px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    margin-top: 45px;
}

.passwordBox {
    width: 288px;
}

.forgotPassword {  
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0d0f7b;
}

.forgotPassword:hover {
    color: #40a9ff;
}

.bigLogo {
    height: 35px;
    /* margin-left: 120px;
    margin-right: 120px;
    margin-top: 20px;
    margin-bottom: 20px; */
}

.header {
    width: 100%;
    height: 100%;
}

.w-100 {
    width: 100%;
}

.header .ant-tabs-nav {
    box-shadow: 0 2px 4px 0 rgba(231, 231, 231, 0.5);
    background-color: #fff;
    margin-bottom: 0px;
}

.header .ant-tabs-tab {
    margin-right: 50px;
}

.header .ant-tabs-content {
    height: 100%;
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-spin-container {
    height: 100%;
}

.table {
    padding-left: 20px;
    padding-right: 20px;
}

.table button {
    width: 100%;
    border-radius: 8px;
    background-color: #1a73e8;
}

.ant-table-cell {
    text-align: center;
    font-family: 'Montserrat'!important;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #202124;
}

.table .ant-table-thead .ant-table-cell {
    font-family: 'Montserrat'!important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    background: #fff;
}
.xls-table .ant-table-thead .ant-table-cell {
   min-width: 130px;
}

.tableTitle {
    display: block;
    font-family: 'Montserrat'!important;
    margin: 34px 22px 35px 80px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
}

.pageContainer {
    display: block;
    font-family: 'Montserrat'!important;
    margin: 34px 22px 35px 40px;
    min-height: 400px;
    padding-bottom: 40px;
    padding-top: 40px;
}

.pageTitle {
    display: block;
    margin: 20px 0px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
}

.newDemand {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e7792b;
}

.rejected {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cf1322;
}

.approved {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ad363;
}

.detailSpin {
    display: block;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
}

.detailSpin .default {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    margin-top: 30px;
}

.detailSpin .newDemand {
    color: #e7792b;
}

.detailSpin .rejected {
    color: #cf1322;
}

.detailSpin .approved {
    color: #5ad363;
}

.detailSpin .tableTitle {
    margin: 0px;
}

.detailSpin .detailsTitle {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    display: block;
    margin-bottom: 15px;
}

.detailSpin .ant-table-cell {
    background: none;
    color: #8f8f8f;
    text-align: center;
    filter: grayscale(0.10);
    font-weight: bold;
}

.detailSpin tr {
    border-bottom-color: #dcdcdc;
}

.detailSpin thead tr {
    border-bottom-width: 2px!important;
}

.detailSpin .ant-table-cell img {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 8px;
}

.header .ant-tabs-tabpane-active {
    margin: 10px;
    width: calc(100% - 20px)!important;
}

.approveListButton {
    width: 152px;
    margin-left: 80px;
    padding: 0 7.2px 0 7.2px;
    border-radius: 8px;
    background-color: #1a73e8;
    height: 66px;
}

.detailRow {
    width: 100%;
}

.detailRow .col {
    padding-right: 60px;
    display: inline-block;
}

.detailRow .title {
    height: 20px;
    flex-grow: 0;
    margin: 0 0 2px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #202124;
    display: block;
}

.detailRow .value {
    height: 20px;
    flex-grow: 0;
    margin: 2px 0 0;
    font-size: 16px;
    text-align: left;
    color: #727272;
    filter: grayscale(0.10);
    display: block;
}

.detailSpin .invoiceControl {
    text-align: left;
    height: 40px;
}

.invoiceControl span {
    display: block;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
}

.invoiceControl .linkSpan {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: #1a73e8;
    margin-top: 5px;
    font-weight: 700;
}

.detailSpin .invoiceName {
    font-size: 12px;
    color: #727272;
}

.newDemandText {
    display: block;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    margin-top: 25px;
    margin-bottom: 25px;
}

.selectDetails {
    width: 280px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
}

.detailsDatePicker {
    width: 280px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
}

.detailsButton {
    height: 48px;
    width: 280px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    display: block;
    margin-top: 25px;
}

.invoiceImg {
    margin: 0px auto;
    height: 70vh;
    display: block;
}

.userDropdown {
    margin-right: 6em;
}

.userDropdownMenu {
    min-width: 140px!important;
    top: 53px !important;
}

.ant-dropdown-link {
    color: #0d0f7b;
}

.dosyaBtn {
    height: 54px;
    width: 180px;
    border-radius: 5px;
}

.supplierBtn {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    height: 35px;
    margin-right: 5px;
}

.ml {
    margin-left: 10px;
}

.excelUploadTable {
    margin-left: -70px;
}

.excelFileBox {
    display: flex;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: rgba(0,0,0,.85);
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 10px 20px;
    width: max-content;
    border-radius: 6px;
    align-items: flex-end;
    margin-bottom: 10px;
}

.excelFileText {
    display: flex;
}

.excelFileIcon {
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    color: #1a73e8;
}

.ant-table-cell {
    text-align: left;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
    border-radius: 0;
    margin-right: 8
}

.ant-table-filter-dropdown-btns .ant-btn {
    width: 90px !important;
    height: 24px !important;
}

.username-box {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    /* margin: 5px 0 5px 20px; */
    /* padding: 6px 9px 4px 10px; */
    border-radius: 25px;
    background-color: #0d0f7b;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.suplier-sideBar {
    width: 320px;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14px 10px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
}

.active {  
    background-color: #f0f7ff;
}

.side-item {  
    width: 100%;
    height: 48px;
    padding: 10px 0px 10px 0px;  
    color: #0d0f7b !important;  
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
}

.text-blue {
    color: #0d0f7b !important;  
}


.limit-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    color: #001444;
    margin-bottom: 10px;
}

.total-limit-box {  
    width: 100%;
    /* height: 131px; */
    padding: 25px 20px 27px 24px;
    /* background-color: #fafbff; */
    border-radius: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.total-limit-box .ant-form-item{     margin: 0;}

.limit-box {    
    height: max-content;
    padding: 0px 0px 0px;
    margin: 35px 0px 20px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
    background-color: #fafbff;
    align-items: baseline;
}

.limit-table-box {    
    height: 560px;
    padding: 0px 0px 10px;
    margin: 20px 0px;
    border-radius: 8px;
    border: solid 1px #e6f1ff;
    background-color: #fff;
}

.limit-input {      
    width: 195px;
    height: 50px;
    margin: 8px 0px 0 0;
    padding: 16px 16px 15px;
    border-radius: 8px;
    border: solid 1px #727272;
    background-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.limit-input::-webkit-outer-spin-button,
.limit-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.limit-input[type=number] {
  -moz-appearance: textfield;
}

.limit-link {
    /* margin: 23px 0 19px 24px; */
    margin-left: 15px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #1251a5;
    text-decoration: underline;
}

.limit-btn {  
    width: 380px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 28px 0px;
    padding: 16px;
    border-radius: 8px;
    background-color: #0d0f7b;
    color: #fff;
}

.supplier-table .ant-table-cell:last-child {
    text-align: left;
}

.files-drop {
    width: 215px;
    margin-left: 0px;
    margin-top: 0px;
}

.files-dp { 
    background: #e6f1ff;
    height: 48px;
    width: 215px;
    padding: 12px;
    color: #001444; 
    margin: 0px;
}

.upload-tabs .ant-tabs-nav-more {
    padding: 0px!important;
}

.footer-box {
    background-color: #001444;
    color: #fff;
    width: 100%;
    padding: 25px 50px 25px 50px;
}

.footer-content {
    justify-content: space-between;
    margin: 0px auto;
    width: 100%;
    align-items: center;
}

.footer-box img .ref-logo {
    height: 24px;
    max-width: 100%;
}

.footer-box .txt {
    font-size: 12px;
    display: grid;
    gap: 12px;
    font-family: Montserrat, sans-serif;
}
.footer-box .lnt {
    font-size: 14px;
    display: grid;
    gap: 24px;
    text-align: left;
    font-family: Montserrat, sans-serif;
}
.footer-box .fnt {
    font-size: 14px;
    display: grid;
    gap: 24px;
    text-align: right;
    font-family: Montserrat, sans-serif;
}
.big-mr {
    margin-right: 80px;
}
.mr-bt {
    margin-bottom: 24px;
}

.left-txt {
    text-align: left;
    color: #fff;
    font-family: Montserrat, sans-serif;
}

.right-txt {
    text-align: right;
    color: #fff;
    font-family: Montserrat, sans-serif;
}

.bold {
    font-weight: 'bold';
}

.text-white {
    color: #fff;
}

.hint-popup .next-btn {
    width: 141px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 56px;
    border-radius: 8px;
    background-color: #0d0f7b;
    color: #fff;
    margin: 10px 0px 0px auto;
}

.hint-modal-btn {
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 56px;
    border-radius: 8px;
    margin-top: 30px;
}

.start-btn {
    width: 116px;
    background-color: #0d0f7b;
    color: #fff;
    margin-left: 20px;
}

.hint-modal-btn:hover, .hint-modal-btn:focus, .hint-modal-btn:active {
    height: 40px;
}

.skip-btn {
    width: 79px;
    background-color: #e9f3ff;
    color: #000;
}

.hint-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.black-sheet {
    width: 100%;
    height: 100vh;
    margin: 0 2px 0 0;
    padding: 64px 122px 33px 108px;
    opacity: 0.4;
    background-color: #131112;
    position: fixed;
    z-index: 1;
}

.hint-dp1 {
    top: 51px !important;
    left: 255px !important;
}

.active-hint {
    z-index: 1;
    padding: 0px 20px;
    border-radius: 5px;
}

.react-joyride__tooltip {
    width: 450px !important;
}

.react-joyride__tooltip div:first-child{
    text-align: left !important;
}

.react-joyride__tooltip div:first-child div{
    padding: 0px 5px 0px!important;
}

.react-joyride__beacon span {
    background-color: #0d0f7b !important ;
    border-color: #0d0f7b !important ;
}

.delete-pop button {
    height: 32px !important;
    width: max-content;
    border-radius: 8px;
    padding: 4px 20px;
}

/* HEADER CSS */
header{
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    display: flex;
    padding: 20px 50px;
    justify-content: space-between;
    align-items: center;
}
header .bigLogo{
    height: auto;
    width: 270px;
  margin:0 ;
}

header nav{
    display: block;
}
header nav a{
    line-height: 64px;
}
header nav button{
    background: rgb(255, 255, 255) !important;
    border: 0px !important;;
    box-shadow: none !important;
    border-radius: 0px !important; 
    padding: 0px !important;
    font-weight: 600 !important;
    margin-right: 40px !important;
    color: rgb(13, 15, 123) !important;
    user-select: none !important;
   
    font-size: 14px !important;
}
header nav button:hover
{
    border-width: 0px 0px 2px !important; 
    border-top-style: initial !important;
    border-right-style: initial !important;
    border-left-style: initial !important;
    border-top-color: initial !important;
    border-right-color: initial !important;
    border-left-color: initial !important;
    border-image: initial !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgb(13, 15, 123) !important;
}
.left-menu-btn{
    margin-top: 20px;
}
.menu-toggle {
    padding: 1em;
    position: absolute;
    top: 2.5em;
    right: 1em;
    cursor: pointer;
    display: none;
}
.custom-menu{
    cursor: pointer;
}

.hamburger{
    cursor: pointer;
}
.hamburger,
.hamburger::before,
.hamburger::after {
    content:'';
    display: block;
    background: #0d0f7b;
    height: 3px;
    width: 1.75em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
    cursor: pointer;
}


.hamburger::before {
    transform: translateY(-7px);
}

.hamburger::after {
    transform: translateY(4px);
}


.open .hamburger {
    transform: rotate(45deg);
    background: #0d0f7b;
}

.open .hamburger::before {
    opacity: 0;            
}

.open .hamburger::after {
    transform: translateY(-3px) rotate(-90deg);
    background: #0d0f7b;
}
.ant-drawer-header-title {
    flex-direction: row-reverse;
}

@media screen and (max-width: 1400px) {
    .menu-toggle{
        display: block;
    }
    header nav{ 
        display: none;
    }
    .custom-menu{
        text-align: center;
        align-items: center;
        display: flex;
        padding-left: 40px;
        width: 100%;
        display: none;
    }
  }
  .mobil-userName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    color: rgb(13, 15, 123) !important;
  }
  .mobil-userName .left{
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 800;
   
  }
  .mobil-userName p{
   margin-bottom: 0;
   color: rgb(13, 15, 123) !important;
   font-weight: 500;
   font-size: 13px;
  }
  .mobil-menu {
    margin-bottom: 0;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .mobil-menu  a{
    line-height: 45px;
  }
  .mobil-menu button{
    background: rgb(255, 255, 255) !important;
    border: 0px !important;;
    box-shadow: none !important;
    border-radius: 0px !important; 
    padding: 0px !important;
    font-weight: 600 !important;
    margin-right: 40px !important;
    color: rgb(13, 15, 123) !important;
    user-select: none !important;
    font-size: 14px !important;
    margin: 5px;
  }
  .mobil-menu button:hover{
    border-width: 0px 0px 2px !important; 
    border-top-style: initial !important;
    border-right-style: initial !important;
    border-left-style: initial !important;
    border-top-color: initial !important;
    border-right-color: initial !important;
    border-left-color: initial !important;
    border-image: initial !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgb(13, 15, 123) !important;
  }
  .my-account-box{
    font-size: 14px;
    font-weight: 600;
    text-align: inherit;
    font-style: initial;
    color: rgb(13, 15, 123);
  }
  .my-account-icon {
    font-size: 16px;
    font-weight: 900;
    text-align: inherit;
    font-style: initial;
    color: rgb(13, 15, 123);
    width: 16px;
  }

  /* Main */

  main{
    display: block;
    max-width: 1200px;
   min-height: calc(100% - 360px);
    width: 1200px;
    max-width: 100%;
    margin: 81px auto 60px;
  }
  @media screen and (max-width: 1200px){
    main{
        padding-left: 30px;
        padding-right: 30px;
    }
  }
  .page-title{
    font-weight: 600 ;
    color: rgb(13, 15, 123) ;
    font-size: 16px ;
  }
  .page-title:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: rgb(13, 15, 123);
}
.page-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 100%;
    max-width: 255px;
    background-color: rgb(13, 15, 123);
}
  .row{
    display: flex;
  }

  /* my suppliers css */
  .my-suppliers{
    margin-top: 50px;
  }
  .my-suppliers .suplier-sideBar .side-item{
    cursor: pointer;
  }
  .tab-box{
    cursor: pointer;
  }
  .my-suppliers .my-suppliers-right .tab-box{
    cursor: pointer;
  }
  @media screen and (max-width: 1280px){
    .my-suppliers{
        display: block !important;
        margin-top: 0 !important;
    }
    .my-suppliers .suplier-sideBar{
        width: 100%;
        max-width: 100%;
        margin-bottom: 40px;
      }
      .my-suppliers .my-suppliers-right{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
  }

/* upload supplier */

@media screen and (max-width: 768px){
    .upload-supplier{
        display: block;
    }
    .upload-supplier>div{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }
}

/* supplier limit */

@media screen and (max-width: 1280px){
    .supplier-limit{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }
}
@media screen and (max-width: 768px){
    .supplier-limit .limit-box{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        display: block;
    }
    .supplier-limit .limit-box .total-limit-box{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }
}
.ant-popover-buttons .ant-btn-primary{
    height: 30px;
}
.divide-btn{
    margin-left: 20px;
    border: none;
    background: none;
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
}

.supplier-list .ant-form-item {
    margin: 0;
}
.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.no-spin {
    -moz-appearance:textfield !important;
}
.input-width{width:100% !important}
.supplier-limit-input .ant-form-item{    margin-bottom: 0;}
.supplier-user-list .ant-table-thead>tr>th:nth-child(3)::before{
    display: none;
}
/* Dashboard */
.dashboard{
    max-width: 1280px;
    margin: 50px auto;
}
.sum-information{
    display: grid;
    grid-template-columns: repeat(auto-fit, 180px);;
    grid-gap: 20px;
    margin: auto;
    /* padding: 20px; */
}
.sum-information> div{
 
    width: 180px;
    height: 175px;
    flex-grow: 0;
    padding:20px;
    border-radius: 16px;
    background-color: #ffeaea;
    display: flex;
    flex-direction: column;
}
.sum-information .icon-box{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    flex-grow: 0;
    margin: 0 120px 10.8px 0;
    padding: 9.8px 11.5px 9.8px 12.5px;
    background-color: #80a8dd;

}
.sum-information .icon-box svg{
    fill: #fff;
    width: 22px;
    height: 22px;
    margin-left: -4px;
    margin-top: -1px;
}
.sum-information .box .txt1{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
}
.sum-information .box .txt2{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #202124;
    position: relative;
}
.sum-information .box .txt2:after{
    content: '';
    width: 26px;
    height: 0.3px;
    flex-grow: 0;
    margin: 0.7px 145px 0 0;
    background-color: #000;
    position: absolute;
    left: 0;
    bottom: 0;
}
.sum-information .box .txt3{
    font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #202124;
}
.sum-information .box .txt4{
    font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #202124;
}
.sum-information .box .txt5{
    font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #202124;
}
.sum-information .box .link{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
    text-decoration: underline;
    text-decoration-color: #484848;
    text-underline-offset: 4px;
}
.sum-information .box1{ 
    background-color: #edf5ff;
}
.sum-information .box2{ 
    background-color: #e9ecff;
}
.sum-information .box3{ 
    background-color: #d2eed8;
}
.sum-information .box4{ 
    background-color: #fff2e9;
}
.sum-information .box5{ 
    background-color: #e2ffe8;
}
.sum-information .box6{ 
    background-color: #f3e8ff;
}
.sum-information .box7{ 
    background-color: #fde6bc;
}
.sum-information .box2 .icon-box{ 
    background-color: #3c4cd8;
}
.sum-information .box3 .icon-box{ 
    background-color: rgb(47, 253, 5);
}
.sum-information .box4 .icon-box{ 
    background-color: #f00;
}
.sum-information .box5 .icon-box{ 
    background-color: #1f762e;
}
.sum-information .box6 .icon-box{ 
    
    background-color: #bf83ff;
}
.sum-information .box7 .icon-box{ 
    background-color: #e7792b;
}
 .title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001444;
}
.supplier-table  td{
    padding: 10px;
}
.supplier-table .dublicate-row td{
    color:#f00;
}
.supplier-table .dublicate-row td:nth-child(1){
    border-right:none;
}
.supplier-table .dublicate-row td:nth-child(2){
    border-left:none;
}
.supplier-table .dublicate-row td:nth-child(2){
    border-right:none;
}
.supplier-table .dublicate-row td:nth-child(3){
    border-left:none;
}
.supplier-table .dublicate-row td:nth-child(3){
    border-right:none;
}
.supplier-table .dublicate-row td:nth-child(4){
    border-left:none;
}
.supplier-table .dublicate-row td:nth-child(4){
    border-right:none;
}
.supplier-table .dublicate-row td:nth-child(5){
    border-left:none;
}

.dashboard .daily-invoices{
    border-radius: 8px;
    border: solid 1px #eef4ff;
    padding-bottom: 8px;
  }

.dashboard .daily-invoices-title{
  padding: 15px 20px 15px 40px;
  background-color: #fafbff;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: #001444;
  height: 54px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.dashboard .daily-invoices-title div{
    width: calc(100% / 3);
    text-align: center;
}
.ant-collapse-header-text {
    display: flex;
}
.dashboard .daily-invoices .ant-collapse-header-text  div{
    width: calc(100% / 3);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
}
.dashboard .daily-invoices .ant-collapse>.ant-collapse-item {
    border: none;
}
.dashboard .daily-invoices .ant-collapse{
    border: none;
    background: none;
}
.dashboard .daily-invoices .ant-collapse-item .ant-collapse-header{
    background: #fafbff;
}
.dashboard .daily-invoices .ant-collapse-item:nth-child(2n + 1) .ant-collapse-header{
    background: #fff;
}
.dashboard .daily-invoices .ant-collapse-content {
    color: rgba(0,0,0,.85);
    background-color: #fff;
    border-top: none;
}
.dashboard .daily-invoices .daily-li {
   margin-bottom: 0;
}
.dashboard .daily-invoices .daily-li li{
    list-style: none;
    display: flex;
}
.dashboard .daily-invoices .daily-li span{
    width: calc(100% / 3);
    text-align: center;
}
.dashboard .invoice-amount-box{
    border-radius: 8px;
  border: solid 1px #eef4ff;
  background-color: #fafbff;
  padding: 30px 0;
}
.dashboard  .limit-risk-box{
    display: flex;
    border-radius: 8px;
    border: solid 1px #eef4ff;
    flex-direction: column;
}
.dashboard  .limit-risk-box .header{
    display: flex;
    background-color: #fafbff;
    height: 54px;
    padding: 15.5px 70.6px 18.5px 26.4px;
}
.dashboard  .limit-risk-box .header div{
    width: calc(100% / 3);
    text-align: center;
    font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #001444;
}
.dashboard  .limit-risk-box .row{
    display: flex;
    background-color: #fff;
    padding: 15.5px 70.6px 18.5px 26.4px;
}
.dashboard  .limit-risk-box .row div{
    width: calc(100% / 3);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #131112;
}
.dashboard  .limit-risk-box .row:nth-child(2n + 1) {
    background: #fafbff;
}
@media only screen and (max-width : 1024px) {
 
 .dashboard   .ant-col-12 {
        display: block; 
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px !important;
    }
    }
    .custom-tooltip{
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
    }
    .custom-tooltip p{
        margin-bottom: 4px;
        padding-bottom: 2px;
        font-weight: 500;
    }
    .limit-acc-style {
        width: 100%;
        border: solid 1px #e6f1ff;
        border-top: none;
    }
    .limit-acc-style .ant-collapse>.ant-collapse-item {
        border-bottom: none;
        border: none;
    }
    .limit-acc-style .ant-collapse-content {        
        border-top:none;
        border:none
    }
    .limit-acc-style  .ant-collapse-arrow {
        margin-top: 12px;
        font-size: 18px !important;
    }
    .acc-row{
        background-color: #f0f7ff;
        border: none;
    }
    .limit-acc-style .header{
        display: flex;
        justify-content: flex-start;
        
    }
    .limit-acc-style .header>div{
        width: calc(100%/6);
        padding: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #001444;       
    }
    .limit-acc-style .header>div:nth-child(1){
        text-align: left;
        min-width: 200px;
        
    }
    .limit-acc-style .header>div:nth-child(2){  
        min-width: 150px;
    }
    .limit-acc-style .header>div:nth-child(6){  
        min-width: 110px;
    }
    .limit-acc-style .header>div:nth-child(5){  
        min-width: 80px;
    }
    .limit-acc-style .header>div:nth-child(4){  
        min-width:230px
    }
    .limit-acc-style .header>div:nth-child(3){  
        min-width:150px
    }
    .table-li{
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    right: 0;
    /* margin-right: 0; */
    width: 100%;
 
    }
    .table-li>div{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    .table-li>div>div{
        width: calc(100%/6);
        padding: 0;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
     
    }
    .table-li>div>div:nth-child(1){       
        min-width: 232px; 
    }
    .table-li>div>div:nth-child(2){
        text-align: left;
        text-align: center;
        align-items: center;
        min-width: 150px; 
    }
    .table-li>div>div:nth-child(3){
        text-align: left;
        min-width: 150px;      
    }
    .table-li>div>div:nth-child(4){
        text-align: left;
       min-width: 230px;      
    }
    .table-li>div>div:nth-child(5){
        text-align: left;
       min-width: 80px;      
    }
    .table-li>div>div:nth-child(6){
        text-align: left;
        min-width: 110px;      
    }
    .table-li>div>div:nth-child(7){  
        min-width: 80px;
    }
    .limit-header-title .title{
        display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8px;
    border: solid 1px #e6f1ff;
    padding-top: 10px; 
    color: #001444;
    border-bottom: none;
    width: 100%;
    padding-left: 20px;
    }
    .limit-header-title .title>div{
        width: calc(100%/6);     
        padding-bottom: 5px;
        font-size: 14px;
        color: #001444;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .limit-header-title .title>div:nth-child(1){
        min-width:200px;
    }
    .limit-header-title .title>div:nth-child(2){
        min-width:150px;
    }
    .limit-header-title .title>div:nth-child(6){
        min-width:110px;
    }
    .limit-header-title .title>div:nth-child(5){
        min-width:80px;
    }
    .limit-header-title .title>div:nth-child(3){
        min-width:150px;
    }
    .limit-header-title .title>div:nth-child(4){
        min-width:230px;
    }
    .supplier-limit-btn{
        width: 70px;
        height: 30px;
        padding: 0;
        border-radius: 8px;
        border: solid 1px #001444;
        background-color: #001444;
        color:#fff;
        margin-left: 5px;
        padding: 0px 4px;
        font-size: 13px;
    }
    .buyer-definitions-li>div div{
        width: calc(100%/5) !important;
        color: #001444;
        font-size: 14px;
    }
    .argo-definitions-li>div div{
        width: calc(100%/4) !important;
        color: #001444;
        font-size: 14px;
    }
    .buyer-definitions-li {        
        border: solid 1px #e6f1ff;
        border-top: none;
    }
    .buyer-definitions-li>div{
        padding:17px 0 ;
    }
    .buyer-definitions-li>div:nth-child(2n-1){
        background-color: #f0f7ff;
    }
    .buyer-definitions-li>div:last-child{    
        background-color: #fff;
        border-top: solid 1px #cbd5e1;
    }
    .buyer-definitions-li>div:last-child div{    
       font-weight: 600;
    }
    .buyer-definitions-title {
        padding-left: 0 !important;
        margin-top: 20px !important;
    }
    .buyer-definitions-title div{        
        padding-left: 0 !important;
        width: calc(100%/5) !important;
    }
    .argo-definitions-title div{
        padding-left: 0 !important;
        width: calc(100%/4) !important;
    }
    .filter-row{
        display:flex;
        justify-content: space-between;
        align-items:flex-end;
      }
    .filter-row>div{
        margin-right:20px
       }
       .filter-row>div>span{
         font-size: 14px;
         font-weight: 600;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.43;
         letter-spacing: normal;
         text-align: center;
         color: #001444;
         padding-left:10px;
         padding-bottom:4px
        }
       .filter-row button{
         border-radius: 8px;
         border: solid 1px #001444;
         background-color: #001444;
         width: 63px;
         height: 30px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
       }
       .filter-row input{
        border-radius: 8px;
        border: solid 1px #e6f1ff;
       }

       .search-argo{
        width: 450px;
      }
      
       @media screen and (max-width: 1100px) {
        .limit-header-title-mobil{
            overflow-x: scroll ;
            
        }
        .limit-header-title-mobil .ant-col-24{
            max-width: initial !important;
        }
      }
    

 @media screen and (max-width: 5000px){
    .search-argo{
        width: 100%;
    }
    .filter-row {
        display: flex;
        justify-content:flex-start;
        align-items: center;
    }

 }

 @media screen and (max-width: 780px) {
    .table-li>div>div:nth-child(1) {
         min-width: auto;
     }
     .limit-header-title .title>div:nth-child(1) {
         min-width: auto;
     }
     .limit-header-title .title>div:nth-child(4) {
         min-width: auto;
     }
     .table-li>div>div:nth-child(4) {
         text-align: left;
         min-width: auto;
     }
     .limit-header-title .title-mobil{
         min-width: 1000px;
     }
     .table-li-mobil{
         min-width: 1000px;
     }
     .mobil-t-title{
         max-width: 1000px;
         overflow-x: scroll;
     }
     .argo-definitions-title{
         border-right: none !important;
     }
     .argo-definitions-li{
         border-right: none !important;
     }
     .argo-definitions-li>div {
         padding: 4px 0;
     }
     
     .filter-row {
         display: flex;
         justify-content:flex-start;
         align-items: center;
     }
  }

.previewContainerStyle{
    width: 250px !important;
    display: flex !important;
}
.previewContainerStyle .anticon-close-circle { 
    font-size: 18px;
    color: red;
    width: 30px;
    margin-right: 10px;
}    
.previewContainerStyle span {
    white-space:normal; 
    overflow:inherit;
    text-overflow: ellipsis;
    margin-top: 4px;
}
.invoiceTable .ant-pagination li:nth-child(1){
    text-wrap: nowrap;
    white-space: nowrap;
    min-width: 120px;
}

.mobil-menu .ant-collapse-content > .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
  
  }
  .modal-login-logout .ant-modal-close-x{
    display: none !important;
  }
  .modal-login-logout  .ant-btn{
    color:#fff !important;
  }
  .modal-login-logout .ant-modal-footer{
    text-align: center;
      border-top: none;
  }
  .modal-login-logout .ant-modal-body {
    padding-bottom: 10px;
  }
  .modal-login-logout .ant-modal-content {
    border-radius: 6px;
    padding-bottom: 20px;
  }
  .modal-login-logout .ant-modal-close-x{
    display: none !important;
  }
  .modal-login-logout  .ant-btn{
    color:#fff !important;
  }
  .modal-login-logout .ant-modal-footer{
    text-align: center;
      border-top: none;
  }
  .modal-login-logout .ant-modal-body {
    padding-bottom: 10px;
  }
  .modal-login-logout .ant-modal-content {
    border-radius: 6px;
  }
  .modal-login-logout  .ant-btn > span {
    color: #fff !important;
  }
  .contract-modal {
    margin-bottom:15px;
    margin-top:15px
  }
  
  .contract-modal-style  {
    width: 800px !important;
    height:500px  !important
  }
  .contract-modal-style .ant-modal-body{
    padding-right: 5px !important;
  }
  .contract-modal-style .ant-tabs-nav{
    margin: 0 auto;
  }
  .contract-modal button{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0 4px;
    border: none;
    height: auto !important;
  }
  .contract-modal button span{
    text-decoration: underline;
    color: #40a9ff;
    border-color: #40a9ff;
  }
  .register-btn span{
    color:#000 !important 
  }
  .contract-modal-style .ant-tabs-content-holder{
    height: 500px;
    overflow-y: scroll;
  }

.membership-modal  .title-center{
    text-align:center;
    margin-top: 30px;
 }
 .contract-modal-style  .ant-tabs-tabpane{
    padding-right: 14px;
 }
 .membership-modal  ul{
    margin-top:5px;
    margin-bottom:15px
 }
 .membership-modal ol{
    margin-left:8px;
    padding-left: 20px;
 }
 .membership-modal table td{
    border:1px solid;
    padding:4px
 }
 .membership-modal .top-li {
    margin-top:15px
  }
  .membership-modal li::marker{
    font-weight:600;
  }
  .membership-modal  span{
    font-weight:600;
    margin-bottom:10px

  }
  .membership-modal  p{
   text-align: justify;

  }
  .delete-invoices-bottom{
    display: flex;
    padding: 30px 0 30px;
    align-items: center;
    /* justify-content: end; */
    gap: 20px;
  }
  .delete-invoices-bottom .ant-btn{
    height: 38px;
  }
  .delete-invoices-modal p{
    margin-bottom: 0;
  }
  .delete-invoices-modal .ant-btn{
    border-radius: 8px;
  }
  .scrollbar{
    max-height: 180px;
    overflow-y: scroll;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .scrollbar::-webkit-scrollbar {
    width: .5rem;
    height: 5px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px white;
    border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

.ant-btn-link {
    color:rgb(13, 15, 123);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-popover{
    min-width: 150px;
    text-align: center;
}

.mobil-menu {
    margin-bottom: 0;
    margin-top: 50px;
  }
  .mobil-menu  a{
    line-height: 45px;
  }
  .mobil-menu button{
    background: rgb(255, 255, 255) !important;
    border: 0px !important;;
    box-shadow: none !important;
    border-radius: 0px !important; 
    padding: 0px !important;
    font-weight: 600 !important;
    margin-right: 40px !important;
    color: rgb(13, 15, 123) !important;
    user-select: none !important;
    font-size: 14px !important;
    margin: 5px;
  }
  .mobil-menu button:hover{
    border-width: 0px 0px 2px !important; 
    border-top-style: initial !important;
    border-right-style: initial !important;
    border-left-style: initial !important;
    border-top-color: initial !important;
    border-right-color: initial !important;
    border-left-color: initial !important;
    border-image: initial !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgb(13, 15, 123) !important;
  }
  .my-account-box{
    font-size: 16px;
    font-weight: 600;
    text-align: inherit;
    font-style: initial;
    color: rgb(13, 15, 123);
  }
  .my-account-icon {
    font-size: 16px;
    font-weight: 900;
    text-align: inherit;
    font-style: initial;
    color: rgb(13, 15, 123);
    width: 16px;
  }
  .ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right:none;
} 
.ant-dropdown-menu-title-content>a {
    color: inherit;
    transition: all .3s;
    color: #0d0f7b;
}
.ant-menu-inline>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-item, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 30px;
    line-height: 30px;
}
.ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 28px;
    line-height: 14px;
    list-style-position: inside;
    list-style-type: disc;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    color: #0d0f7b;
}
.account-div li{
    list-style: none;
    padding: 10px 0;
    margin-left: 0;
}
.account-div {
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.account-div li span{
    font-weight: bold;
    display: flex;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
}
.notInvoiceValid-modal .ant-modal-body{
    max-height: 400px;
    overflow-y: scroll;
}
.ant-picker-large .ant-picker-input>input {
    font-size: 14px;
}
.suppliers-movie input{
    border:none !important
}
.suppliers-movie .ant-btn-lg{ font-size: 14px !important;}
.upload-invoices-modal p {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 16px;
}
.upload-invoices-modal h4 {
    text-decoration: underline;
    font-weight: 600;
}
.password-update .ant-form-item{
    margin-bottom: 0;
}
.list-new-th .ant-table-thead>tr>th{
    padding-left: 20px !important;
}
.summary-cell-result {
    background-color: #fafbff;
}
.ant-table-summary>tr>td, .ant-table-summary>tr>th{
    background-color: #fafbff;
}
.summary-cell-result td:nth-child(1){
    font-weight: bold;
    color: #011545;
}
.summary-cell-result td:nth-child(2){
    white-space:pre-line;
    color: #011545;
    font-weight: 600;
}
.summary-cell-result td:nth-child(3){
    white-space:pre-line;
    color: #011545;
    font-weight: 600;
}
.summary-cell-result td:nth-child(4){
    white-space:pre-line;
    color: #011545;
    font-weight: 600;
}
.select-bg-new .ant-select-selection-item{
    color: #bfbfbf;
}
.update-batch-btn-grp{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 344px;
    border: 1px solid;
    float: right;
    padding: 10px;
    border:1px solid #E6F1FF;
    border-radius: 8px;
}
.update-batch-btn-grp button{
    border-radius: 7px;
    border: solid 1px #001444;
    background-color: #001444;
    width: 152px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.donwload-limit-now{
    border-radius: 7px;
    border: solid 1px #001444;
    background-color: #001444;
   
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1400px) {
    .update-batch-row{
        flex-direction: column-reverse;
    }
    .update-batch-row>div{
        flex: 100%;
        max-width: 100%;
    }
    .update-batch-btn-grp{
        margin-bottom: 15px;
    }
  
}
@media screen and (max-width: 800px) {
  
    .donwload-limit-now{
        margin-top: 15px;
    }
}


